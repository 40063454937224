import "./App.scss";
import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/landing/footer/Footer.js";
import { ToastContainer } from "react-toastify";
import useEagerConnect from "./hooks/useEagerConnect";
import "react-toastify/dist/ReactToastify.css";
import Token_staking from "./components/TokenStaking/Token_staking";
// import Footernew from './components/landing/FooterNew/Footernew';
import Useraagreement from "./components/Privacypolicy/Useraagreement";
import Privacypolicy from "./components/Privacypolicy/Privacypolicy";

function App() {
  useEagerConnect();
  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        {/* <Navbar/> */}
        <Switch>
          {/* <Route exact path='/' component={Landing} /> */}
          <Route exact path="/" component={Token_staking} />
          <Route exact path="/useragreement" component={Useraagreement} />
          <Route exact path="/privacy" component={Privacypolicy} />
          {/* <Route exact path='/tokenstaking' component={Token_staking} /> */}
          {/* <Route exact path='/tokenpioneer' component={TokenPioneer} /> */}
        </Switch>
        <Footer />
        {/* <Footernew /> */}
      </Router>
    </>
  );
}

export default App;
